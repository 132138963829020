<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-tabs>
          <a-tab-pane key="2" tab="器材清单" v-if="btnList.includes('器材清单')">
            <equipment-list></equipment-list>
          </a-tab-pane>
          <a-tab-pane key="6" tab="工程器材清单" v-if="btnList.includes('工程器材清单')">
            <engineer-equipment-list></engineer-equipment-list>
          </a-tab-pane>
          <a-tab-pane key="3" tab="项目底图" v-if="btnList.includes('项目底图')">
            <basemap></basemap>
          </a-tab-pane>
          <a-tab-pane key="4" tab="场景信息" v-if="btnList.includes('场景信息')">
            <monitor-point-pic></monitor-point-pic>
          </a-tab-pane>
          <a-tab-pane key="5" tab="抄表设置" v-if="btnList.includes('抄表设置')">
            <meter-reading></meter-reading>
          </a-tab-pane>
          <a-tab-pane key="7" tab="摄像机关联设置" v-if="btnList.includes('摄像机关联设置')">
            <qrcode-contrast></qrcode-contrast>
          </a-tab-pane>
          <a-tab-pane key="8" tab="巡查点档案设置" v-if="btnList.includes('巡查点档案设置')">
            <inspection-point></inspection-point>
          </a-tab-pane>
          <a-tab-pane key="9" tab="作业点档案设置" v-if="btnList.includes('作业点档案设置')">
            <operation-point></operation-point>
          </a-tab-pane>
          <a-tab-pane key="10" tab="绿化区域平面图" v-if="btnList.includes('绿化区域平面图')">
            <afforest-area-pic></afforest-area-pic>
          </a-tab-pane>
          <a-tab-pane key="11" tab="工单处理人员" v-if="btnList.includes('工单处理人员')">
            <repairs-group-management></repairs-group-management>
          </a-tab-pane>
          <a-tab-pane key="12" tab="专属维修条目" v-if="btnList.includes('专属维修条目')">
            <exclusive-repair></exclusive-repair>
          </a-tab-pane>
          <a-tab-pane key="13" tab="维修时间配置" v-if="btnList.includes('维修时间配置')">
            <point-params></point-params>
          </a-tab-pane>
      </a-tabs>
      </div>
      <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    </div>
  </page-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey} from "U/index";
import addOrEditModal from "V/patrols/patrolsSetting/storeFiles/addOrEditModal";
import basemap from "../basemap/Index";
import monitorPointPic from "../SceneInfo/Index";
import equipmentList from "../equipmentList/Index";
import engineerEquipmentList from "../engineerEquipmentList/Index";
import meterReading from "../meterReading/Index";
import qrcodeContrast from "../qrcodeContrast/Index";
import inspectionPoint from "../inspectionPoint/Index";
import operationPoint from "../workPoint/Index";
import afforestAreaPic from "../afforestAreaPic/Index";
import repairsGroupManagement from "../repairsGroupManagement/Index.vue";
import exclusiveRepair from "../exclusiveRepair/Index.vue";
import pointParams from "../pointParams/Index.vue";
import {deleteApplicationFile, getApplicationFileByCondition} from "A/patrol";
import {fileTypes} from "@/json/wlwhistory";
import {getMonitorPointListByCondition} from "A/monitoring";
import deptselect from "@/mixins/deptselect";
export default {
  name: "storeFiles",
  mixins: [deptselect,pagination],
  components:{
    addOrEditModal,
    basemap,
    equipmentList,
    engineerEquipmentList,
    monitorPointPic,
    meterReading,
    qrcodeContrast,
    inspectionPoint,
    operationPoint,
    afforestAreaPic,
    repairsGroupManagement,
    exclusiveRepair,
    pointParams,
  },
  data(){
    return{
      moment,
      showAdvanced: false,
      fileTypes,
      buttonTypes:{'value1':'','value2':'primary','value3':'','value4':''},
      queryParams: {
        monitorpointnum: '',
        monitorpointname:'',
        address: '',
        monitorpointid:'',
        filename:'',
        fileurl:'',
        filetype:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' }
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      index:1,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "设置"
    },
    breadcrumb() {
      const pages = [{name:"项目设置",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    // this.getTableData(true)
    this.initDeptOptionsAll();
  },
  methods: {
    getAllTableData(record){
      this.currentPage = 1;
      this.tableColumns=[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '文件名',
          dataIndex: 'filename',
          key: 'filename',
          ellipsis: true,
        },
        {
          title: '文件类型',
          dataIndex: 'filetype',
          key: 'filetype',
          ellipsis: true,
          scopedSlots: { customRender: 'filetype' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ]
      this.index=0
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        filetype:null,
        monitorpointnum:record.monitorpointnum,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getApplicationFileByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      if(this.index==1||this.index==2||this.index==3){
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
          {
            title: '文件名',
            dataIndex: 'filename',
            key: 'filename',
            ellipsis: true,
          },
          // {
          //   title: '文件类型',
          //   dataIndex: 'filetype',
          //   key: 'filetype',
          //   ellipsis: true,
          //   scopedSlots: { customRender: 'filetype' }
          // },
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: 'operation' }
          },
        ]
        this.tableLoading = true;
        let params = {
          ...this.queryParams,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getApplicationFileByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
            scopedSlots: { customRender: 'monitorpointname' }
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: 'operation' }
          },
        ]
        let params = {
          ...this.queryParams,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMonitorPointListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    changeButton(index){
      if(index==0){
        this.buttonTypes.value1='primary'
        this.buttonTypes.value2=''
        this.buttonTypes.value3=''
        this.buttonTypes.value4=''
        this.queryParams.filetype=null
        // this.tableColumns=[
        //   {
        //     title: '项目名称',
        //     dataIndex: 'monitorpointname',
        //     key: 'monitorpointname',
        //     ellipsis: true,
        //     scopedSlots: { customRender: 'monitorpointname' }
        //   },
        //   {
        //     title: '地址',
        //     dataIndex: 'address',
        //     key: 'address',
        //     ellipsis: true,
        //   },
        //   {
        //     title: '操作',
        //     key: 'operation',
        //     align: 'center',
        //     width: 70,
        //     scopedSlots: { customRender: 'operation' }
        //   },
        // ]
        this.index=0
        this.getTableData(true)
      }else if(index==1){
        this.buttonTypes.value1=''
        this.buttonTypes.value2='primary'
        this.buttonTypes.value3=''
        this.buttonTypes.value4=''
        this.queryParams.filetype=1
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
          {
            title: '文件名',
            dataIndex: 'filename',
            key: 'filename',
            ellipsis: true,
          },
          {
            title: '文件类型',
            dataIndex: 'filetype',
            key: 'filetype',
            ellipsis: true,
            scopedSlots: { customRender: 'filetype' }
          },
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: 'operation' }
          },
        ]
        this.index=1
        this.getTableData(true)
      } else if(index==2){
        this.buttonTypes.value1=''
        this.buttonTypes.value2=''
        this.buttonTypes.value3='primary'
        this.buttonTypes.value4=''
        this.queryParams.filetype=2
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
          {
            title: '文件名',
            dataIndex: 'filename',
            key: 'filename',
            ellipsis: true,
          },
          {
            title: '文件类型',
            dataIndex: 'filetype',
            key: 'filetype',
            ellipsis: true,
            scopedSlots: { customRender: 'filetype' }
          },
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: 'operation' }
          },
        ]
        this.index=2
        this.getTableData(true)
      }else if(index==3){
        this.buttonTypes.value1=''
        this.buttonTypes.value2=''
        this.buttonTypes.value3=''
        this.buttonTypes.value4='primary'
        this.queryParams.filetype=3
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
          {
            title: '文件名',
            dataIndex: 'filename',
            key: 'filename',
            ellipsis: true,
          },
          {
            title: '文件类型',
            dataIndex: 'filetype',
            key: 'filetype',
            ellipsis: true,
            scopedSlots: { customRender: 'filetype' }
          },
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: 'operation' }
          },
        ]
        this.index=3
        this.getTableData(true)
      }
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.userdepidCascaderSelected=[]
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = this.index;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let applicationfileid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'applicationfileid', applicationfileid);
      if(type == 'detail') {
        this.showModal('detail',record,record)
      }else if(type == 'edit') {
        this.showModal('edit',record,record)
      }else if(type == 'add'){
        this.showModal('add')
      }else if(type == 'delete') {
        this.deleteConfirm(applicationfileid, record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.applicationfileid);
      }).catch(()=>{
      });
    },
    delete(applicationfileid) {
      this.showLoading();
      if(applicationfileid) {
        let params = {
          applicationfileid
        };
        deleteApplicationFile(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>