<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" style="width: 25%">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="width: 25%;float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
              <a-form-model-item label="项目ID" prop="monitorpointnum" style="width: 25%">
                <a-input v-model.trim="queryParams.monitorpointnum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="区域" prop="areacode" style="width: 25%">
                <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
              </a-form-model-item>
              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname" style="width: 25%">
                <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
              </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">项目列表</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="onlineorder" slot-scope="value">
            <a-tag :color="value==4?'green':value==0?'red':value==1?'':''">{{value==4?'在线':value==0?'离线':value==1?'无设备':value}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="showModal(value, record)">配置</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" title="项目参数" :width="1200">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <div class="simple-query">
        <a-form-model ref="queryForm" :model="formData" layout="inline" class="query-form">
          <h3 style="font-size: large;font-weight: bold">专属维修服务时间</h3>
          <a-form-model-item label="周一上午" prop="mon_am_able" style="width: 15%">
            <a-select v-model="formData.mon_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="mon_am_order" style="width: 15%">
            <a-select v-model="formData.mon_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周一下午" prop="mon_pm_able" style="width: 15%">
            <a-select v-model="formData.mon_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="mon_pm_order" style="width: 15%">
            <a-select v-model="formData.mon_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周一晚上" prop="mon_nt_able" style="width: 15%">
            <a-select v-model="formData.mon_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="mon_nt_order" style="width: 15%">
            <a-select v-model="formData.mon_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周二上午" prop="tue_am_able" style="width: 15%">
            <a-select v-model="formData.tue_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="tue_am_order" style="width: 15%">
            <a-select v-model="formData.tue_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周二下午" prop="tue_pm_able" style="width: 15%">
            <a-select v-model="formData.tue_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="tue_pm_order" style="width: 15%">
            <a-select v-model="formData.tue_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周二晚上" prop="tue_nt_able" style="width: 15%">
            <a-select v-model="formData.tue_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="tue_nt_order" style="width: 15%">
            <a-select v-model="formData.tue_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周三上午" prop="wed_am_able" style="width: 15%">
            <a-select v-model="formData.wed_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="wed_am_order" style="width: 15%">
            <a-select v-model="formData.wed_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周三下午" prop="wed_pm_able" style="width: 15%">
            <a-select v-model="formData.wed_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="wed_pm_order" style="width: 15%">
            <a-select v-model="formData.wed_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周三晚上" prop="wed_nt_able" style="width: 15%">
            <a-select v-model="formData.wed_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="wed_nt_order" style="width: 15%">
            <a-select v-model="formData.wed_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周四上午" prop="thu_am_able" style="width: 15%">
            <a-select v-model="formData.thu_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="thu_am_order" style="width: 15%">
            <a-select v-model="formData.thu_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周四下午" prop="thu_pm_able" style="width: 15%">
            <a-select v-model="formData.thu_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="thu_pm_order" style="width: 15%">
            <a-select v-model="formData.thu_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周四晚上" prop="thu_nt_able" style="width: 15%">
            <a-select v-model="formData.thu_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="thu_nt_order" style="width: 15%">
            <a-select v-model="formData.thu_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周五上午" prop="fri_am_able" style="width: 15%">
            <a-select v-model="formData.fri_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="fri_am_order" style="width: 15%">
            <a-select v-model="formData.fri_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周五下午" prop="fri_pm_able" style="width: 15%">
            <a-select v-model="formData.fri_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="fri_pm_order" style="width: 15%">
            <a-select v-model="formData.fri_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周五晚上" prop="fri_nt_able" style="width: 15%">
            <a-select v-model="formData.fri_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="fri_nt_order" style="width: 15%">
            <a-select v-model="formData.fri_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周六上午" prop="sat_am_able" style="width: 15%">
            <a-select v-model="formData.sat_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="sat_am_order" style="width: 15%">
            <a-select v-model="formData.sat_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周六下午" prop="sat_pm_able" style="width: 15%">
            <a-select v-model="formData.sat_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="sat_pm_order" style="width: 15%">
            <a-select v-model="formData.sat_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周六晚上" prop="sat_nt_able" style="width: 15%">
            <a-select v-model="formData.sat_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="sat_nt_order" style="width: 15%">
            <a-select v-model="formData.sat_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周日上午" prop="sun_am_able" style="width: 15%">
            <a-select v-model="formData.sun_am_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="sun_am_order" style="width: 15%">
            <a-select v-model="formData.sun_am_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周日下午" prop="sun_pm_able" style="width: 15%">
            <a-select v-model="formData.sun_pm_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="sun_pm_order" style="width: 15%">
            <a-select v-model="formData.sun_pm_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="周日晚上" prop="sun_nt_able" style="width: 15%">
            <a-select v-model="formData.sun_nt_able" placeholder="" style="width: 70px">
              <a-select-option value="0">禁用</a-select-option>
              <a-select-option value="1">使能</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最多接单数量" prop="sun_nt_order" style="width: 15%">
            <a-select v-model="formData.sun_nt_order" placeholder="" style="width: 70px">
              <a-select-option v-for="(item,index) in 10" :key="index" :value="item">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getTimeRange} from 'U'
import {mapState} from "vuex";
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import {
  addPointParams,
  getMonitorPointinfoById,
  getMonitorPointListByCondition,
  getPointParamsListByCondition,
  modifyMonitorPointSystemRole, modifyPointParams
} from "A/monitoring";
import {getDictByDicType} from "A/xtpz";
export default {
  name:'pointParams',
  mixins: [areaselect, deptselect, pagination],
  data() {
    return {
      showAdvanced: false,
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      queryParams: {
        monitorpointnum: '',
        address: '',
        areacode: '',
        monitornum:'',
        userdepname:'',
        devicenum:'',
        userdepid:'',
        userdepsid:'',
      },
      tableColumns: [
        {
          title: '项目ID',
          dataIndex: 'monitorpointnum',
          key: 'monitorpointnum',
          ellipsis: true,
          width: 165
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      paramsAdd:false,
      formData:{
        monitorpointnum:'',
        mon_am_able:'0',
        mon_am_order:'3',
        mon_pm_able:'0',
        mon_pm_order:'3',
        mon_nt_able:'0',
        mon_nt_order:'3',
        tue_am_able:'0',
        tue_am_order:'3',
        tue_pm_able:'0',
        tue_pm_order:'3',
        tue_nt_able:'0',
        tue_nt_order:'3',
        wed_am_able:'0',
        wed_am_order:'3',
        wed_pm_able:'0',
        wed_pm_order:'3',
        wed_nt_able:'0',
        wed_nt_order:'3',
        thu_am_able:'0',
        thu_am_order:'3',
        thu_pm_able:'0',
        thu_pm_order:'3',
        thu_nt_able:'0',
        thu_nt_order:'3',
        fri_am_able:'0',
        fri_am_order:'3',
        fri_pm_able:'0',
        fri_pm_order:'3',
        fri_nt_able:'0',
        fri_nt_order:'3',
        sat_am_able:'0',
        sat_am_order:'3',
        sat_pm_able:'0',
        sat_pm_order:'3',
        sat_nt_able:'0',
        sat_nt_order:'3',
        sun_am_able:'0',
        sun_am_order:'3',
        sun_pm_able:'0',
        sun_pm_order:'3',
        sun_nt_able:'0',
        sun_nt_order:'3',
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '项目参数';
    },
  },
  watch:{
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.initDeptOptionsAll();
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.formData={
        monitorpointnum:'',
        mon_am_able:'0',
        mon_am_order:'3',
        mon_pm_able:'0',
        mon_pm_order:'3',
        mon_nt_able:'0',
        mon_nt_order:'3',
        tue_am_able:'0',
        tue_am_order:'3',
        tue_pm_able:'0',
        tue_pm_order:'3',
        tue_nt_able:'0',
        tue_nt_order:'3',
        wed_am_able:'0',
        wed_am_order:'3',
        wed_pm_able:'0',
        wed_pm_order:'3',
        wed_nt_able:'0',
        wed_nt_order:'3',
        thu_am_able:'0',
        thu_am_order:'3',
        thu_pm_able:'0',
        thu_pm_order:'3',
        thu_nt_able:'0',
        thu_nt_order:'3',
        fri_am_able:'0',
        fri_am_order:'3',
        fri_pm_able:'0',
        fri_pm_order:'3',
        fri_nt_able:'0',
        fri_nt_order:'3',
        sat_am_able:'0',
        sat_am_order:'3',
        sat_pm_able:'0',
        sat_pm_order:'3',
        sat_nt_able:'0',
        sat_nt_order:'3',
        sun_am_able:'0',
        sun_am_order:'3',
        sun_pm_able:'0',
        sun_pm_order:'3',
        sun_nt_able:'0',
        sun_nt_order:'3',
      }
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorPointListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showModal(value,record) {
      this.formData.monitorpointnum = record.monitorpointnum
      getPointParamsListByCondition({monitorpointnum: record.monitorpointnum}).then(res => {
        if (res && res.returncode === '0') {
          if (res.item.length > 0) {
            this.paramsAdd = false
            this.formData = res.item[0]
          } else {
            this.paramsAdd = true
          }
          this.modalVisible = true;
        }
      })
    },
    confirm(){
      if(this.paramsAdd){
        addPointParams({...this.formData}).then(res=>{
          if(res&&res.returncode==='0'){
            this.paramsAdd=false
            this.modalVisible = false;
            this.$message.success("修改成功")
          }
        })
      }else{
        modifyPointParams({...this.formData}).then(res=>{
          if(res&&res.returncode==='0'){
            this.paramsAdd=false
            this.modalVisible = false;
            this.$message.success("修改成功")
          }
        })
      }
    },
  }
}
</script>