<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="500" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" :rules="formRules" labelAlign="right" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select style="width: 240px" placeholder="请选择" v-model="queryParams.monitorpointnum" :disabled="showType=='detail'" show-search :filter-option="filterOption" >
            <a-select-option v-for="item in monitorpointNameList" :key="item.monitorpointnum">
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="场景类型" prop="scenetype">
          <a-select v-model="queryParams.scenetype" style="width: 240px" :disabled="showType=='detail'" @change="getSceneList(queryParams.scenetype)">
            <a-select-option v-for="(item, index) in sceneTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="场景模板" prop="scenetemplateid">
          <a-select v-model="queryParams.scenetemplateid" style="width: 240px" :disabled="showType=='detail'">
            <a-select-option v-for="(item, index) in sceneLists" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="信息类型" prop="sceneinfotype">
          <a-select v-model="queryParams.sceneinfotype" style="width: 240px" :disabled="showType=='detail'">
            <a-select-option v-for="(item, index) in picTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==1||queryParams.sceneinfotype==2" label="文件名" prop="sceneinfopicname">
          <a-input v-model.trim="queryParams.sceneinfopicname" placeholder="请输入" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==1||queryParams.sceneinfotype==2" label="上传文件" prop="sceneinfopicurl">
          <a-upload
              :disabled="showType=='detail'"
              style="width: 240px"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadSceneInfoPic"
              action="/upload"
              :file-list="SceneInfoPicUrlList"
              @preview="handlePreview"
              @change="SceneInfoUrlUploaded">
            <a-button v-if="showType=='add'||showType=='edit'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==3&&queryParams.scenetype==1" label="水箱容积" prop="param1">
          <a-input v-model.trim="queryParams.param1" placeholder="请加上单位，例如:600升" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==3&&queryParams.scenetype==1" label="高区供水压力" prop="param2">
          <a-input v-model.trim="queryParams.param2" placeholder="请加上单位，例如:1.2MPa" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==3&&queryParams.scenetype==1" label="低区供水压力" prop="param3">
          <a-input v-model.trim="queryParams.param3" placeholder="请加上单位，例如:0.8MPa" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==3&&queryParams.scenetype==2" label="1#变压器容量" prop="param1">
          <a-input v-model.trim="queryParams.param1" placeholder="请加上单位，例如:" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==3&&queryParams.scenetype==2" label="2#变压器容量" prop="param2">
          <a-input v-model.trim="queryParams.param2" placeholder="请加上单位，例如:" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==3&&queryParams.scenetype==2" label="发电机功率" prop="param3">
          <a-input v-model.trim="queryParams.param3" placeholder="请加上单位，例如:" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.sceneinfotype==4&&queryParams.scenetype==2" label="馈电回路电流警戒值" prop="configuration1">
          <a-input v-model.trim="queryParams.configuration1" placeholder="请加上单位，例如: 900A" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-show="false" label="设置人" prop="recordman">
          <a-input v-model.trim="queryParams.recordman" placeholder="" style="width: 240px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-show="false" label="设置时间" prop="recordtime">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.recordtime" style="width: 240px;" :disabled="true"/>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {getMonitorPointNameList, getMonitorPointNameListByCondition} from "A/monitoring";
import { addSceneInfo, modifySceneInfo} from "A/patrol";
import {getCache} from "U/index";
import {getMonitorPointSceneList} from "A/jcgn";
import moment from "moment";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      previewFile: '',
      baseurl:'https://fda.u-lake.com/',
      monitorpointNameList: [],
      picTypes:[
        {"dickey":"1","dicvalue":"系统图"},
        {"dickey":"2","dicvalue":"平面图"},
        {"dickey":"3","dicvalue":"标准参数"},
        {"dickey":"4","dicvalue":"配置参数"},
      ],
      sceneTypes:[],
      sceneLists:[],
      queryParams:{
        sceneinfoid:'',
        monitorpointid:'',
        monitorpointnum:'',
        monitorpointname:'',
        address:'',
        sceneinfopicurl:'',
        sceneinfopicname:'',
        sceneinfotype:'',
        scenetype:'',
        scenetemplateid:'',
        param1:'',
        param2:'',
        param3:'',
        configuration1:'',
        recordman:'',
        recordtime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        scenetype: [{required: true, message: '请选择场景类型'}],
        scenetemplateid: [{required: true, message: '请选择场景模板'}],
        sceneinfotype: [{required: true, message: '请选择信息类型'}],
      },
      SceneInfoPicUrlList:null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadSceneInfoPic']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getSceneList();
        this.getMonitorpointName();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.sceneTypes = dictionary.sceneTypes;
    }
    this.queryParams.recordman=this.userInfo.username
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointNameList=res.item
        }
      })
    },
    getSceneList(scenetype){
      this.queryParams.scenetemplateid=''
      getMonitorPointSceneList({scenetype:scenetype}).then(res => {
        if (res && res.returncode == '0') {
          this.sceneLists = res.item;
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            if(key=='sceneinfotype'||key=='scenetype'||key=='scenetemplateid'){
              this.queryParams[key] = this.detailData[key].toString()||'';
            }else if(key=='recordtime'){
              if(this.showType == 'edit'){
                this.queryParams[key] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss") || '';
              }else{
                this.queryParams[key] = moment(this.detailData[key], 'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss") || '';
              }
            }else{
              this.queryParams[key] = this.detailData[key]||'';
            }
          }
          if (this.detailData.sceneinfopicurl) {
            let nameIndex = this.detailData.sceneinfopicurl.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.sceneinfopicurl.lastIndexOf('\\');
            }
            let fileName = this.detailData.sceneinfopicurl.substr(nameIndex + 1);
            this.SceneInfoPicUrlList = [{
              uid: 'sceneinfouid',
              name: fileName,
              status: 'done',
              url: this.detailData.sceneinfopicurl,
            }];
          }
        })
      }
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
      this.SceneInfoPicUrlList=null
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            this.queryParams.recordtime = moment(this.queryParams.recordtime).format("YYYYMMDDHHmmss")
            this.queryParams.recordman=this.userInfo.username
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addSceneInfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifySceneInfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async handlePreview() {
      this.previewFile = this.baseurl+this.queryParams.sceneinfopicurl;
      window.open(this.previewFile)
    },
    beforeUpload(file) {
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      // if (!isJpgOrPng) {
      //   this.$message.error('支持上传的图片类型为jpg/png格式!');
      // }
      const isLt1M = file.size / 1024 /1024 < 5;
      if (!isLt1M) {
        this.$message.error('文件大小最大支持上传5MB!');
      }
      return isLt1M;
    },
    SceneInfoUrlUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.queryParams.sceneinfopicurl = file.response.urlPath;
        }
        return file;
      });
      this.SceneInfoPicUrlList = fileList;
    },
  }
}
</script>