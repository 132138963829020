<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -35px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title" style="margin-right: 100px">{{pageTitle}}</h3>
          公司/项目：<a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
          <div class="table-btns">
            <a-button @click="resetQueryParams">重置</a-button>
            <a-button type="primary" class="after-btn" @click="getTableData(true)" style="margin-right: 15px">查询</a-button>
            <a-button v-if="userInfo.rolename=='项目管理员'||userInfo.rolename=='项目调度员'||userInfo.rolename=='项目其他管理员'" type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="recordtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.afforestareapicid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.afforestareapicid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.afforestareapicid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    </div>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import moment from "moment";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey} from "U/index";
import addOrEditModal from "V/patrols/patrolsSetting/afforestAreaPic/addOrEditModal";
import {deleteAfforestAreaPic, getAfforestAreaPicListByCondition} from "A/patrol";
import deptselect from "@/mixins/deptselect";
export default {
  name: "afforestAreaPic",
  mixins: [deptselect,pagination],
  components:{
    addOrEditModal,
  },
  data(){
    return{
      moment,
      showAdvanced: false,
      queryParams: {
        monitorpointnum: '',
        monitorpointname:'',
        afforestareapicname:'',
        recordman:'',
        recordtime:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '绿化区域平面图名称',
          dataIndex: 'afforestareapicname',
          key: 'afforestareapicname',
          ellipsis: true,
        },
        {
          title: '设置人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 100,
        },
        {
          title: '设置时间',
          dataIndex: 'recordtime',
          key: 'recordtime',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'recordtime' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "绿化区域图片"
    },
    breadcrumb() {
      const pages = [{name:"项目设置",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.initDeptOptionsAll();
    this.getTableData()
  },
  methods: {
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getAfforestAreaPicListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[];
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let afforestareapicid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'afforestareapicid', afforestareapicid);
      if(type == 'detail') {
        this.showModal('detail',record,record)
      }else if(type == 'edit') {
        this.showModal('edit',record,record)
      }else if(type == 'add'){
        this.showModal('add')
      }else if(type == 'delete') {
        this.deleteConfirm(afforestareapicid, record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.afforestareapicid);
      }).catch(()=>{
      });
    },
    delete(afforestareapicid) {
      this.showLoading();
      if(afforestareapicid) {
        let params = {
          afforestareapicid
        };
        deleteAfforestAreaPic(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  },
}
</script>
<style scoped>

</style>