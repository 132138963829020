<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -35px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title" style="margin-right: 100px">{{pageTitle}}</h3>
          公司/项目：<a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
          <div class="table-btns">
            <a-button @click="resetQueryParams">重置</a-button>
            <a-button type="primary" class="after-btn" @click="getTableData(true)" style="margin-right: 15px">查询</a-button>
            <a-button v-if="userInfo.usertype===0||userInfo.rolename==='项目管理员'||userInfo.rolename==='项目其他管理员'||userInfo.rolename==='项目工程管理员'||userInfo.rolename==='督导管理员（工程管理）'" type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index"  :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="meter_reading_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.qrcode_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.qrcode_id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.qrcode_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, measure_range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    </div>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import moment from "moment";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import addOrEditModal from "V/patrols/patrolsSetting/qrcodeContrast/addOrEditModal";
import {deleteQrcodeContrast, getQrcodeContrastListByCondition} from "A/facilityandequipment";
import {getMonitorPointNameList} from "A/monitoring";
import deptselect from "@/mixins/deptselect";
export default {
  name: "meterReading",
  mixins: [deptselect,pagination],
  components:{
    addOrEditModal,
  },
  data(){
    return{
      moment,
      showAdvanced: false,
      queryParams: {
        monitorpointnum:'',
        monitorpointname:'',
        qrcode_type:'',
        qrcode_name:'',
        qrcode_num:'',
        qrcode_address:'',
        qrcode_axial:'',
        monitor_num:'',
        userdepsid:'',
      },
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '类型',
          dataIndex: 'qrcode_type',
          key: 'qrcode_type',
          ellipsis: true,
        },
        {
          title: '名称',
          dataIndex: 'qrcode_name',
          key: 'qrcode_name',
          ellipsis: true,
          align:'center',
        },
        {
          title: '编号',
          dataIndex: 'qrcode_num',
          key: 'qrcode_num',
          ellipsis: true,
        },
        {
          title: '位置',
          dataIndex: 'qrcode_address',
          key: 'qrcode_address',
          ellipsis: true,
        },
        {
          title: '轴位',
          dataIndex: 'qrcode_axial',
          key: 'qrcode_axial',
          ellipsis: true,
        },
        {
          title: '关联摄像机名称/编号',
          dataIndex: 'monitor_num',
          key: 'monitor_num',
          ellipsis: true,
        },
        {
          title: '设置人',
          dataIndex: 'creator',
          key: 'creator',
          ellipsis: true,
          scopedSlots: { customRender: 'creator' },
        },
        {
          title: '设置时间',
          dataIndex: 'create_time',
          key: 'create_time',
          ellipsis: true,
          scopedSlots: { customRender: 'create_time' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "摄像机关联设置"
    },
    breadcrumb() {
      const pages = [{name:"项目设置",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
    }
    this.getTableData()
  },
  methods: {
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getQrcodeContrastListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[];
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    getMonitorPointName(){
      getMonitorPointNameList({}).then(res=>{
        if(res&&res.returncode=='0') {
          this.monitorpointNameList = res.item
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let qrcode_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'qrcode_id', qrcode_id);
      if(type == 'detail') {
        this.showModal('detail',record,record)
      }else if(type == 'edit') {
        this.showModal('edit',record,record)
      }else if(type == 'add'){
        this.showModal('add')
      }else if(type == 'delete') {
        this.deleteConfirm(qrcode_id, record)
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalShowType = "detail";
            this.modalDetailData = record;
            this.modalVisible = true;
          },
        },
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.qrcode_id);
      }).catch(()=>{
      });
    },
    delete(qrcode_id) {
      this.showLoading();
      if(qrcode_id) {
        let params = {
          qrcode_id
        };
        deleteQrcodeContrast(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>