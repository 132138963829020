export const repairOrderSteps = [
  {}
]

export const categoryList = {
  "1":"电气",
  "2":"电子",
  "3":"水",
  "4":"门锁五金",
  "5":"泥木工",
  "6":"空调",
}

export const electricalItemsList = {
  "1":"更换灯具",
  "2":"更换开关/插座面板",
  "3":"更换开关箱开关",
  "4":"室内线路维修",
  "5":"电器安装",
  "6":"电器维修",
}

export const electronItemsList = {
  "1":"电子装置安装",
  "2":"电子装置维修",
  "3":"电子线路维修",
}

export const waterItemsList = {
  "1":"更换水件",
  "2":"更换管道",
  "3":"维修管道",
  "4":"防补漏",
  "5":"更换/移位厨卫浴装置",
  "6":"更换水泵",
  "7":"维修水泵",
  "8":"疏通管道",
}

export const hardwareItemsList = {
  "1":"换锁",
  "2":"检修/换锁芯",
  "3":"破锁",
  "4":"把手安装",
  "5":"把手维修",
  "6":"铰链更换",
  "7":"铰链调整",
}

export const mudWoodItemsList = {
  "1":"室内挂件",
  "2":"杆件安装",
  "3":"冲击钻打孔",
  "4":"室内天地墙维修",
}

export const airConditionItemsList = {
  "1":"泠凝水管维修",
  "2":"检修VAV变风量动力箱",
  "3":"更换/移位室内旋转风口",
  "4":"检修盘管风机",
  "5":"清洗盘管风机尘网",
  "6":"IT机房空调冷冻水",
}