<template>
  <a-modal v-model="modalVisible" title="选择设施设备" :width="900" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="设备类型" prop="equipment_type">
<!--          <a-select v-model="queryParams.equipment_type" @change="getEquipmentCategory(queryParams.equipment_type)" style="width: 135px">-->
            <a-select v-model="queryParams.equipment_type" style="width: 135px">
            <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备名称" prop="equipment_name">
          <a-input v-model="queryParams.equipment_name" style="width: 135px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="设备编号" prop="equipment_num">
          <a-input v-model="queryParams.equipment_num" style="width: 135px"></a-input>
        </a-form-model-item>
        <a-form-model-item style="float: right">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="directories_id"
               :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }"
               :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
         <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
        <span slot="equipment_status" slot-scope="value,record">{{equipmentStatusList[value]?equipmentStatusList[value].dicvalue:''}}</span>
<!--        <span slot="equipment_num" slot-scope="value,record"><a-button style="margin-left: -15px" type="link" @click="showModal('detail',record,'1')">{{value}}</a-button></span>-->
        <span slot="equipment_category" slot-scope="value,record">{{equipmentCategoryMap[value]?equipmentCategoryMap[value]:''}}</span>
        <span slot="equipment_child_category" slot-scope="value,record">{{equipmentChildCategoryMap[value]?equipmentChildCategoryMap[value]:''}}</span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import moment from "moment";
import {getCache, getTimeRange} from "U/index";
import {getEquipDirectoriesListByCondition} from "A/facilityandequipment";
export default {
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    // selectType: {
    //   default: 'checkbox'
    // },
    selectType: {
      default: 'radio'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    },
    formData:{
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      queryParams: {
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        equipment_category:'',
        equipment_child_category:'',
        is_fire_equipment:'',
        archives_num:'',
        equipment_level:'',
        purpose:'',
        address:'',
        equipment_status:'',
        qrcode:'',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_num' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          align:'center',
          width: 80,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备类别',
          dataIndex: 'equipment_category',
          key: 'equipment_category',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'equipment_category' },
        },
        {
          title: '设备子类别',
          dataIndex: 'equipment_child_category',
          key: 'equipment_child_category',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'equipment_child_category' },
        },
        // {
        //   title: '用途',
        //   dataIndex: 'purpose',
        //   key: 'purpose',
        //   ellipsis: true,
        //   width: 80,
        // },
        // {
        //   title: '安装位置',
        //   dataIndex: 'address',
        //   key: 'address',
        //   ellipsis: true,
        //   width: 80,
        // },
        // {
        //   title: '设备状态',
        //   dataIndex: 'equipment_status',
        //   key: 'equipment_status',
        //   ellipsis: true,
        //   align:'center',
        //   width: 80,
        //   scopedSlots: { customRender: 'equipment_status' },
        // },
      ],
      tableData: [],
      selectedRows: [],
      deviceCodeList:[],
      equipmentStatusList:[],
      equipmentCategoryMap:{},
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
      equipmentChildCategoryMap:[],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.directories_id);
      return currentSelectKeys;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.planstarttime = start;
      this.queryParams.planendtime = end;
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
  },
  methods: {
    init() {
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
        this.equipmentStatusList = dictionary.equipmentStatusList
        this.equipmentCategoryList = dictionary.equipmentCategoryList
        this.equipmentCategoryMap = dictionary.equipmentCategoryMap
        this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
        this.equipmentChildCategoryMap = dictionary.equipmentChildCategoryMap
      }
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData();
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
      this.queryInstallDate=null
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        is_history:'0',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getEquipDirectoriesListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.directories_id) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.directories_id!=record.directories_id)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.directories_id) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.directories_id!=record.directories_id)
        })
      }
    },
    confirm() {
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
    }
  }
}
</script>