<template>
  <page-container title="工单处理人员管理" >
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointnum" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="工单处理组名称" prop="repairs_group_name" style="width: 25%">
              <a-input v-model.trim="queryParams.repairs_group_name" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="审核人员名称" prop="group_man_name" style="width: 25%">
              <a-input v-model.trim="queryParams.group_man_name" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
              <!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="maintenancedepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1'||item.deptype=='3')" v-model="deptCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">工单处理组列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="repairs_group_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="memberList" slot-scope="value" class="status-view">
            <span class="text" :title="(value && value.length) ? value.map(item => item.username).join(',') : ''">{{(value && value.length) ? value.map(item => item.username).join(',') : ''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.repairs_group_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.repairs_group_id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.repairs_group_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :bodyStyle="{maxHeight: '800px', overflow: 'auto'}">
      <template slot="footer">
        <a-button v-if="modalType=='add'||modalType=='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="modalType==='detail'" v-model="formData.monitorpointnum" placeholder="请选择项目" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="工单处理组类型" prop="group_type">
          <a-select :disabled="modalType==='detail'" v-model.trim="formData.group_type">
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="工单处理组名称" prop="repairs_group_name">
          <a-input :disabled="modalType==='detail'" v-model.trim="formData.repairs_group_name" />
        </a-form-model-item>
        <a-form-model-item label="审核人员" prop="managerUserShow">
          <a-input :value="formData.managerUserShow" disabled style="width: 180px;" />
          <a-button v-if="modalType==='edit'||modalType==='add'" type="primary" @click="openSelectUserModal(formData.group_type==='报事'?'项目其他管理员':'项目工程管理员')" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="工单处理人员" prop="groupMembersShow">
          <a-input :value="formData.groupMembersShow" disabled style="width: 180px;" />
          <a-button v-if="modalType==='edit'||modalType==='add'" type="primary" @click="openSelectUserModal(formData.group_type==='报事'?'':'项目工程')" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <select-user key="selectmanager" :visible.sync="selectManagerVisible" @get-selected-rows="getManagerUser" select-type="radio" :default-selected="managerUser?[managerUser]:[]" :params="selectUserParams"></select-user>
    <select-user key="selectmembers" :visible.sync="selectGroupMembersVisible" @get-selected-rows="getGroupMembers" :default-selected="groupMembers" :params="selectUserParams"></select-user>
  </page-container>
</template>
<script>
import deptselect from '@/mixins/deptselect'
import { getRepairsGroupListByCondition, addRepairsGroup, modifyRepairsGroup, deleteRepairsGroup, getRepairsGroupById, bindRepairsMember } from 'A/repairsreport'
import { getItemFromArrayByKey} from 'U'
import store from '@/store'
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getUserDepNameByCondition} from "A/monitoring";
import SelectUser from "V/patrols/patrolsSetting/repairsGroupManagement/SelectUser";
export default {
  name: 'repairsGroupManagement',
  mixins: [deptselect,pagination],
  components: {
    SelectUser
  },
  data() {
    return {
      showAdvanced: false,
      breadcrumb: [
        {
          name: '工单处理组',
          path: ''
        },
      ],
      queryParams: {
        repairs_group_name: '',
        group_man_account: '',
        group_man_name: '',
        monitorpointnum: '',
        group_type: '',
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '工单处理组类型',
          dataIndex: 'group_type',
          key: 'group_type',
          ellipsis: true,
        },
        {
          title: '工单处理组名称',
          dataIndex: 'repairs_group_name',
          key: 'repairs_group_name',
          ellipsis: true,
        },
        {
          title: '审核人员',
          dataIndex: 'group_man_name',
          key: 'group_man_name',
          ellipsis: true,
        },
        {
          title: '工单处理人员',
          dataIndex: 'memberList',
          key: 'memberList',
          ellipsis: true,
          scopedSlots: { customRender: 'memberList' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 60,
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      userdepidCascaderSelected: [],
      modalType: '',
      formData: {
        repairs_group_id: '',
        repairs_group_name: '',
        monitorpointnum: '',
        managerUserShow: '',
        groupMembersShow: '',
        group_type: '',
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        group_type: [{required: true, message: '请选择工单处理组类型'}],
        repairs_group_name: [{required: true, message: '请输入工单处理组名称'}],
        managerUserShow: [{required: true, message: '请选择审核人员'}],
        groupMembersShow: [{required: true, message: '请选择工单处理人员'}],
      },
      selectManagerVisible: false,
      managerUser: null,
      selectGroupMembersVisible: false,
      groupMembers: null,
      monitorpointList:[],

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    modalTitle() {
      let title = '';
      if(this.modalType == 'add') {
        title = '新增';
      }else if(this.modalType == 'detail') {
        title = '详情';
      }else if(this.modalType == 'edit') {
        title = '修改';
      }else {
        title = '';
      }
      return title;
    },
    selectUserParams() {
      return {
        usertypes: "1",
        isequipmentman: "false",
        rolename: ""
      }
    }
  },
  watch: {
    managerUser(val) {
      if(val) {
        this.formData.managerUserShow = val.username
        this.$refs.modalForm.clearValidate('managerUserShow');
      }else {
        this.formData.managerUserShow = ''
      }
    },
    groupMembers(val) {
      if(val && val.length) {
        let result = '';
        if(val && val.length) {
          val.forEach(item => {
            if(result) {
              result += ','
            }
            result += item.username
          })
        }
        this.formData.groupMembersShow = result
      }else {
        this.formData.groupMembersShow = ''
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
    this.getMonitorPointNameList();
  },
  methods: {
    init() {
      this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
      for(let i=0;i<this.menu.children.length;i++){
        this.btnList.push(this.menu.children[i].menuname)
      }
      this.getTableData();
    },
    getMonitorPointNameList(){
      getUserDepNameByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetQueryParams() {
      this.deptCascaderSelected = [];
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        userdepid:this.userInfo.userdepid,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      this.tableLoading = true;
      getRepairsGroupListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.repairs_group_id);
      }).catch(()=>{
      });
    },
    delete(repairs_group_id) {
      this.showLoading();
      if(repairs_group_id) {
        let params = {
          repairs_group_id
        };
        deleteRepairsGroup(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let repairs_group_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'repairs_group_id', repairs_group_id);
      if(type == 'delete') {
        this.deleteConfirm(repairs_group_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record) {
      this.modalType = type;
      if(type == 'add') {
        this.groupMembers=null
        this.managerUser=null
        this.modalVisible = true;
        this.$nextTick(() => {
          this.$refs.modalForm.resetFields();
        })
      }else {
        this.modalVisible = true;
        this.showLoading();
        this.$nextTick(() => {
          let params = {
            repairs_group_id: record.repairs_group_id
          }
          getRepairsGroupById(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              record = res.item;
              this.formData.monitorpointnum = record.monitorpointnum;
              this.formData.repairs_group_id = record.repairs_group_id;
              this.formData.repairs_group_name = record.repairs_group_name;
              this.formData.group_type = record.group_type;
              if(record.group_man_id) {
                this.managerUser = {
                  userid: record.group_man_id,
                  username: record.group_man_name,
                };
              }else {
                this.managerUser = null;
              }
              if(record.memberList && record.memberList.length) {
                this.groupMembers = record.memberList;
              }else {
                this.groupMembers = null;
              }
            }else {
              this.$message.error(res.errormsg||'查询失败');
            }
          })
        })
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    modalConfirm() {
      if(this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.addOrEdit();
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    addOrEdit() {
      this.showLoading();
      let memberids = '';
      if(this.groupMembers && this.groupMembers.length) {
        memberids = this.groupMembers.map(item => item.userid).join('|')
      }else if(this.formData.groupMembersShow) {
        memberids = this.formData.groupMembersShow
      }
      let params = {
        monitorpointnum: this.formData.monitorpointnum,
        group_type: this.formData.group_type,
        repairs_group_name: this.formData.repairs_group_name,
        group_man_id: this.managerUser ? this.managerUser.userid : this.formData.managerUserShow,
        memberids: memberids,
      };
      if(this.modalType == 'add') {
        addRepairsGroup(params).then(this.addOrEditResult);
      }else if(this.modalType == 'edit') {
        params.repairs_group_id = this.formData.repairs_group_id
        modifyRepairsGroup(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if(res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      }else {
        this.$message.error(res.errormsg||'操作失败');
      }
    },
    getManagerUser(selectedRows) {
      this.managerUser = selectedRows[0];
    },
    getGroupMembers(selectedRows) {
      this.groupMembers = selectedRows;
    },
    openSelectUserModal(type){
      if(type==="项目工程管理员"){
        this.selectUserParams.rolename="项目工程管理员"
        this.selectManagerVisible=true
      }else if(type==="项目工程"){
        this.selectUserParams.rolename=""
        this.selectGroupMembersVisible=true
      }else if(type==="项目其他管理员"){
        this.selectUserParams.rolename="项目其他管理员"
        this.selectManagerVisible=true
      }else{
        this.selectUserParams.rolename=""
        this.selectGroupMembersVisible=true
      }
    },
    unitChange() {
      this.formData.managerUserShow = []
      this.formData.groupMembersShow = []
      this.managerUser=null
      this.groupMembers=null
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>