<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="800" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" class="query-form" :rules="formRules" layout="inline" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="项目名称" prop="monitorpointnum" style="width: 45%">
          <a-select placeholder="请选择" v-model="queryParams.monitorpointnum" :disabled="showType=='detail'" @change="getEquipDirectoriesListByCategory()" show-search :filter-option="filterOption" >
            <a-select-option v-for="item in monitorpointNameList" :key="item.monitorpointnum">
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备名称" prop="equipment_num" style="width: 45%">
          <a-select show-search :filter-option="filterOption" :disabled="true" v-model.trim="queryParams.equipment_num"  @change="getEquipDirectories()" style="width: 125px">
            <a-select-option v-for="(item,index) in equipmentNumList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
          <a-button :disabled="showType=='detail'" type="primary" style="margin-left: 5px" @click="selectEquipments">选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="设备类型" prop="equipment_type" style="width: 45%">
          <a-select :disabled="true" v-model="queryParams.equipment_type"  @change="getEquipmentCategory(queryParams.equipment_type)">
            <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备类别" prop="equipment_category" style="width: 45%">
          <a-select :disabled="true" v-model="queryParams.equipment_category"  @change="getEquipmentChildCategory(queryParams.equipment_category)">
            <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备子类别" prop="equipment_child_category" style="width: 45%">
          <a-select :disabled="true" v-model="queryParams.equipment_child_category"  @change="getEquipDirectoriesListByCategory">
            <a-select-option v-for="(item, index) in equipmentChildCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="仪表名称" prop="meter_reading_device" style="width: 45%">
          <a-input v-model.trim="queryParams.meter_reading_device" placeholder="" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="表号" prop="meter_num" style="width: 45%">
          <a-input v-model.trim="queryParams.meter_num" placeholder="" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="组名" prop="group_name"  style="width: 45%">
          <a-input v-model.trim="queryParams.group_name" placeholder="" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="所在机房" prop="machine_room_name" style="width: 45%">
          <a-input v-model.trim="queryParams.machine_room_name" placeholder="" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="测量类型" prop="measure_type" style="width: 45%">
          <a-select v-model="queryParams.measure_type" :disabled="showType=='detail'">
            <a-select-option v-for="(item, index) in MeasureTypeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="测量单位" prop="measure_unit" style="width: 45%">
          <a-select v-model="queryParams.measure_unit" :disabled="showType=='detail'">
            <a-select-option v-for="(item, index) in MeasureUnitList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="标准值" prop="standard_value" style="width: 45%">
<!--          <a-input v-model.trim="queryParams.standard_value" placeholder="" :disabled="showType=='detail'"></a-input>-->
          <a-select v-model.trim="queryParams.standard_value" placeholder="" :disabled="showType=='detail'">
            <a-select-option value="=">等于</a-select-option>
            <a-select-option value=">">大于</a-select-option>
            <a-select-option value="≥">大于等于</a-select-option>
            <a-select-option value="<">小于</a-select-option>
            <a-select-option value="≤">小于等于</a-select-option>
            <a-select-option value="><">两者之间（开区间）</a-select-option>
            <a-select-option value="≥≤">两者之间（闭区间）</a-select-option>
            <a-select-option value="≥<">两者之间（左闭右开）</a-select-option>
            <a-select-option value=">≤">两者之间（左开右闭）</a-select-option>
            <a-select-option value="<>">两者开外（开区间）</a-select-option>
            <a-select-option value="≤≥">两者开外（闭区间）</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.standard_value==='='||queryParams.standard_value==='>'||queryParams.standard_value==='≥'||queryParams.standard_value==='<'||queryParams.standard_value==='≤'" label="范围" prop="measure_range" style="width: 45%">
<!--          <a-input v-model.trim="queryParams.measure_range" placeholder="" :disabled="showType=='detail'"></a-input>-->
          <a-input v-model.trim="queryParams.measure_range" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" placeholder="请输入数字或者小数" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.standard_value!='='&&queryParams.standard_value!='>'&&queryParams.standard_value!='≥'&&queryParams.standard_value!='<'&&queryParams.standard_value!='≤'" label="范围" prop="measure_range_left" style="width: 45%">
          <a-input-group compact :disabled="showType=='detail'">
            <a-input style=" width: 74px; text-align: center" placeholder="" v-model.trim="measure_range_left" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" placeholder="请输入数字或者小数" :disabled="showType=='detail'"/>
            <a-input style=" width: 50px; border-left: 0; pointer-events: none;" placeholder="~" disabled/>
            <a-input style=" width: 74px; text-align: center; border-left: 0" placeholder="" v-model.trim="measure_range_right" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')" placeholder="请输入数字或者小数" :disabled="showType=='detail'"/>
          </a-input-group>
        </a-form-model-item>
      </a-form-model>
    </div>
    <select-equipments :visible.sync="selectEquipmentVisible" @get-selected-rows="getEquipmentInfo" :params="selectEquipmentParams"></select-equipments>
  </a-modal>
</template>
<script>
import {getMonitorPointNameList, getMonitorPointNameListByCondition} from "A/monitoring";
import {addMeterReading, modifyMeterReading} from "A/patrol";
import {mapState} from "vuex";
import {getCache} from "U/index";
import moment from "moment";
import {getEquipDirectoriesList, getEquipDirectoriesListByCondition} from "A/facilityandequipment";
import {getSecondaryDeviceList, getThirdDeviceList} from "A/jcgn";
import SelectEquipments from "C/modals/SelectEquipments.vue";
import SelectUser from "V/patrols/patrolsSetting/repairsGroupManagement/SelectUser.vue";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  components:{
    SelectEquipments
  },
  data() {
    return {
      modalVisible: false,
      selectEquipmentVisible:false,
      selectEquipmentParams:{},
      MeasureTypeList:[],
      MeasureUnitList:[],
      StandardValueMap:{},
      queryParams:{
        meter_reading_id:'',
        monitorpointnum:'',
        group_name:'',
        equipment_num:'',
        equipment_type:'',
        equipment_category:'',
        equipment_child_category:'',
        meter_reading_device:'',
        meter_num:'',
        measure_range:'',
        measure_type:'',
        standard_value:'',
        measure_unit:'',
        meter_reading_type:'0',
        machine_room_name:'',
        measure_range_left:'',
        measure_range_right:'',
        creator:'',
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        equipment_num: [{required: true, message: '请选择设备名称'}],
        meter_reading_device: [{required: true, message: '请选择仪表名称'}],
        measure_range: [{required: true, message: '请选择测量范围，仅允许输入数字或者小数'}],
        measure_range_left: [{required: true, message: '请选择测量范围，仅允许输入数字或者小数'}],
        measure_type: [{required: true, message: '请选择测量类型'}],
        standard_value: [{required: true, message: '请输入标准值'}],
        measure_unit: [{required: true, message: '请选择测量单位'}],
      },
      formData:{
        userdepsid:'',
      },
      monitorpointNameList:[],
      equipmentNumList:[],
      measure_range_left:'',
      measure_range_right:'',
      deviceCodeList:[],
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if(dictionary) {
          let valuesToFilter = ['电度', '水表', '燃气'];
          this.MeasureTypeList = dictionary.MeasureTypeList.filter(v=> !valuesToFilter.includes(v.dickey));
          this.MeasureUnitList = dictionary.MeasureUnitList;
          this.StandardValueMap = dictionary.StandardValueMap;
        }
        this.initDetail()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    'measure_range_left':function (val){
      this.queryParams.measure_range_left=val
    },
  },
  created() {
    this.getMonitorpointName();
    this.getEquipDirectoriesList();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentCategoryList = dictionary.equipmentCategoryList
      this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
    }
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointNameList=res.item
          this.queryParams.monitorpointnum=res.item[0].monitorpointnum
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            if(key === 'equipment_type'){
              this.queryParams[key] = this.detailData[key] || '';
              this.getEquipDirectoriesList(this.queryParams[key])
            }else if(key === 'devicecode'){
              this.queryParams[key] = this.detailData[key] || '';
              this.getEquipmentCategory(this.queryParams[key])
            }else if (key == 'meter_reading_time') {
              if (this.showType == 'edit') {
                this.queryParams[key] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss") || '';
              } else {
                this.queryParams[key] = moment(this.detailData[key], 'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss") || '';
              }
            } else if(key == 'measure_range'){
              this.queryParams[key] = this.detailData[key] || '';
              this.measure_range_left=this.queryParams[key].split(",")[0]
              this.measure_range_right=this.queryParams[key].split(",")[1]
            }else {
              this.queryParams[key] = this.detailData[key] || '';
            }
          }
        })
      }
    },
    resetDatas() {
      this.measure_range_left='',
      this.measure_range_right='',
      this.$refs.queryForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            if(this.queryParams.standard_value!='='&&this.queryParams.standard_value!='>'&&this.queryParams.standard_value!='≥'&&this.queryParams.standard_value!='<'&&this.queryParams.standard_value!='≤'){
              this.queryParams.measure_range=this.measure_range_left+","+this.measure_range_right
            }
            this.queryParams.creator=this.userInfo.username
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addMeterReading(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifyMeterReading(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getEquipDirectories(){
      getEquipDirectoriesListByCondition({monitorpointnum:this.queryParams.monitorpointnum,equipment_num: this.queryParams.equipment_num}).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            this.queryParams.machine_room_name=res.item[0].machine_room_name
          }
        }
      })
    },
    getEquipDirectoriesList(equipment_type){
      getEquipDirectoriesList({equipment_type:equipment_type,is_history:'0'}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList=res.item
        }
      })
    },
    getEquipmentCategory(devicecode){
      this.queryParams.equipment_category=""
      getSecondaryDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentCategoryList=res.item
          this.getEquipDirectoriesList(this.queryParams.equipment_type)
        }
      })
    },
    getEquipmentChildCategory(devicecode){
      this.queryParams.equipment_child_category=""
      getThirdDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentChildCategoryList=res.item
          this.getEquipDirectoriesListByCategory()
        }
      })
    },
    getEquipDirectoriesListByCategory(){
      getEquipDirectoriesList({is_history: 0,monitorpointnum:this.queryParams.monitorpointnum,equipment_type:this.queryParams.equipment_type,equipment_category:this.queryParams.equipment_category,equipment_child_category:this.queryParams.equipment_child_category}).then(res => {
        if(res && res.returncode == '0') {
          this.equipmentNumList=res.item
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    selectEquipments(){
      this.selectEquipmentVisible=true
    },
    getEquipmentInfo(selectedRows){
      this.queryParams.equipment_num=selectedRows[0].equipment_num
      this.queryParams.equipment_type=selectedRows[0].equipment_type
      this.queryParams.equipment_category =selectedRows[0].equipment_category
      this.queryParams.equipment_child_category=selectedRows[0].equipment_child_category
    }
  }
}
</script>