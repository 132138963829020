<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="500" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" class="query-form" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="器材名称和数量" prop="equipmentname">
          <a-input v-model.trim="queryParams.equipmentname" placeholder="请输入" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="器材类型" prop="equipmenttype">
          <a-select style="width: 240px" placeholder="请选择" v-model="queryParams.equipmenttype" :disabled="showType=='detail'">
            <a-select-option v-for="(item, index) in equipmentTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-show="false" label="设置人" prop="recordman">
          <a-input v-model.trim="queryParams.recordman" placeholder="" style="width: 240px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-show="false" label="设置时间" prop="recordtime">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.recordtime" style="width: 240px;" :disabled="true"/>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>

import {getMonitorPointNameList} from "A/monitoring";
import {addEngineerEquipmentList, modifyEngineerEquipmentList} from "A/patrol";
import {mapState} from "vuex";
import {getCache} from "U/index";
import moment from "moment";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      equipmentTypes:[],
      queryParams:{
        equipmentlistid:'',
        monitorpointid:'',
        equipmentname:'',
        equipmenttype:'',
        monitorpointnum:'',
        monitorpointname:'',
        address:'',
        recordman:'',
        recordtime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      formRules: {
        equipmentname: [{required: true, message: '请输入器材名称和数量'}],
        equipmenttype: [{required: true, message: '请选择器材类型'}],
      },
      monitorpointNameList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if(dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
          this.alarmTypes = dictionary.alarmTypes;
          this.alarmTypesMap = dictionary.alarmTypesMap;
          this.systemNames = dictionary.systemNames;
          this.systemNamesMap = dictionary.systemNamesMap;
          this.feedbackList = dictionary.feedbackList;
          this.abnormalTypes = dictionary.abnormalTypes;
          this.abnormalTypesMap = dictionary.abnormalTypesMap;
          this.equipmentTypes = dictionary.equipmentTypes;
          this.manageTypes = dictionary.manageTypes;
          this.recordTypes = dictionary.recordTypes;
        }
        this.initDetail()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.queryParams.recordman=this.userInfo.username
    this.getMonitorpointName();
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item[0].monitorpointnum
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            if (key == 'recordtime') {
              if (this.showType == 'edit') {
                this.queryParams[key] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss") || '';
              } else {
                this.queryParams[key] = moment(this.detailData[key], 'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss") || '';
              }
            } else {
              this.queryParams[key] = this.detailData[key] || '';
            }
          }
        })
      }
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            this.queryParams.recordtime = moment(this.queryParams.recordtime).format("YYYYMMDDHHmmss")
            this.queryParams.recordman=this.userInfo.username
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addEngineerEquipmentList(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifyEngineerEquipmentList(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
  }
}
</script>