<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="550" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类别" prop="category">
          <a-auto-complete
              :disabled="showType==='detail'"
              v-model="formData.category"
              :data-source="categoryList"
              :filterOption="true"
              @select="onSelect"
              @search="onSearch"
              @change="changeItemList"
          />
        </a-form-model-item>
        <a-form-model-item label="服务项目" prop="items">
          <a-auto-complete
              :disabled="showType==='detail'"
              v-model="formData.items"
              :data-source="itemsList"
              :filterOption="true"
              @select="onSelect"
              @search="onSearch"
              @change="changeEntryList"
          />
        </a-form-model-item>
        <a-form-model-item label="规格" prop="entry">
          <a-auto-complete
              :disabled="showType==='detail'"
              v-model="formData.entry"
              :data-source="entryList"
              :filterOption="true"
              @select="onSelect"
              @search="onSearch"
              @change="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="人工费" prop="price">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.price"/>
        </a-form-model-item>
        <a-form-model-item label="物料费" prop="material_costs">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.material_costs"/>
        </a-form-model-item>
<!--        <a-form-model-item label="维修时间" prop="" :required="true">-->
<!--          <a-time-picker :disabled="showType=='detail'" :value="start_time?moment(start_time,'HH:mm'):null" @change="onChange1" style="width: 142px"></a-time-picker> ~-->
<!--          <a-time-picker :disabled="showType=='detail'" :value="end_time?moment(end_time,'HH:mm'):null" @change="onChange2" style="width: 142px"></a-time-picker>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 235px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addExclusiveRepair,
  getExclusiveRepairListByCondition,
  modifyExclusiveRepair
} from "A/repairsreport";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '450px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        category: '',
        items: '',
        entry: '',
        price: '',
        material_costs: '不含物料费',
        // repair_validity: '',
        recordman: '',
        remark:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        category: [{required: true, message: '请输入类别'}],
        items: [{required: true, message: '请输入服务项目'}],
        entry: [{required: true, message: '请输入规格'}],
        price: [{required: true, message: '请输入人工费'}],
      },
      start_time:"",
      end_time:"",
      monitorpointList:[],
      entryList:[],
      itemsList:[],
      categoryList:["电气","电子","水","门锁五金","泥木工","空调"],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)];
    },
    onSelect(value) {
      //console.log('onSelect', value);
    },
    onChange(value) {
      //console.log('onChange', value);
    },
    changeItemList(val){
      if(val==="电气"){
        this.itemsList=["更换灯具","更换开关/插座面板","更换开关箱开关","室内线路维修","电器安装","电器维修"]
      }else if(val==="电子"){
        this.itemsList=["电子装置安装","电子装置维修","电子线路维修"]
      }else if(val==="水"){
        this.itemsList=["更换水件","更换管道","维修管道","防补漏","更换/移位厨卫浴装置","更换水泵","维修水泵","疏通管道"]
      }else if(val==="门锁五金"){
        this.itemsList=["换锁","检修/换锁芯","破锁","把手安装","把手维修","铰链更换","铰链调整"]
      }else if(val==="泥木工"){
        this.itemsList=["室内挂件","杆件安装","冲击钻打孔","室内天地墙维修"]
      }else if(val==="空调"){
        this.itemsList=["泠凝水管维修","检修VAV变风量动力箱","更换/移位室内旋转风口","检修盘管风机","清洗盘管风机尘网","IT机房空调冷冻水"]
      }
    },
    changeEntryList(val){
      if(this.formData.category==="电气"&&val==="更换灯具"){
        this.entryList=["日光灯","灯泡","高空灯具","吸顶灯","轨道灯"]
      }else if(this.formData.category==="电气"&&val==="更换开关箱开关"){
        this.entryList=["空气开关","漏电开关","闸刀开关"]
      }else if(this.formData.category==="电气"&&val==="电器安装"){
        this.entryList=["吊扇","排风机","油烟机","洗衣机"]
      }else if(this.formData.category==="电子"&&val==="电子装置安装"){
        this.entryList=["网络","电话","电视","防盗装置","消防装置"]
      }else if(this.formData.category==="水"&&val==="更换水件"){
        this.entryList=["水龙头","阀门","软管","连接件"]
      }else if(this.formData.category==="水"&&val==="更换管道"){
        this.entryList=["热熔管","PVC管","钢管"]
      }else if(this.formData.category==="水"&&val==="防补漏"){
        this.entryList=["落水","接头","台面"]
      }else if(this.formData.category==="水"&&val==="更换/移位厨卫浴装置"){
        this.entryList=["水槽","马桶","热水器","炉具"]
      }else if(this.formData.category==="水"&&val==="疏通管道"){
        this.entryList=["管道","马桶","浴缸","水槽"]
      }else if(this.formData.category==="门锁五金"&&val==="换锁"){
        this.entryList=["普通锁","防盗锁","玻璃门锁"]
      }else if(this.formData.category==="门锁五金"&&val==="铰链更换"){
        this.entryList=["铰链","地弹簧","闭门器"]
      }else if(this.formData.category==="泥木工"&&val==="室内挂件"){
        this.entryList=["镜","画","挂钩"]
      }else if(this.formData.category==="泥木工"&&val==="杆件安装"){
        this.entryList=["窗帘杆","浴帘杆","晾衣杆"]
      }else if(this.formData.category==="泥木工"&&val==="室内天地墙维修"){
        this.entryList=["修补","粉刷","贴砖"]
      }else{
        this.entryList=[]
      }
    },
    onChange1(time, timeString){
      this.start_time=timeString
    },
    onChange2(time, timeString){
      this.end_time=timeString
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      // this.queryInstallDate = []
      // this.start_time=null
      // this.end_time=null
    },
    initDetail() {
      this.resetData()
      if (this.detailData && this.detailData.entry_id) {
        if(this.showType == 'edit' || this.showType == 'detail') {
          this.$nextTick(() => {
            let params = {
              entry_id: this.detailData.entry_id
            }
            getExclusiveRepairListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                // this.start_time=res.item[0].repair_validity?moment(res.item[0].repair_validity.split("~")[0],"HH:mm:ss"):""
                // this.end_time=res.item[0].repair_validity?moment(res.item[0].repair_validity.split("~")[1],"HH:mm:ss"):""
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            // this.start_time=this.start_time.length===8?this.start_time:moment(this.start_time).format("HH:mm:ss")
            // this.end_time=this.end_time.length===8?this.end_time:moment(this.end_time).format("HH:mm:ss")
            // this.formData.repair_validity=this.start_time+"~"+this.end_time
            this.formData.recordman=this.userInfo.username
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addExclusiveRepair(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyExclusiveRepair(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>