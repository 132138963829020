<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="800" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" class="query-form" :rules="formRules" layout="inline" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="项目名称" prop="monitorpointnum" style="width: 45%">
          <a-select v-model="queryParams.monitorpointnum" :disabled="showType=='detail'" show-search :filter-option="filterOption">
            <a-select-option v-for="item in monitorpointNameList" :key="item.monitorpointnum">
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="qrcode_type" style="width: 45%">
          <a-select :disabled="showType=='detail'" v-model="queryParams.qrcode_type" @change="getQueryCondition">
            <a-select-option value="设备">设备</a-select-option>
            <a-select-option value="巡查点">巡查点</a-select-option>
            <a-select-option value="机房">机房</a-select-option>
            <a-select-option value="作业点">作业点</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="qrcode_name" style="width: 45%">
<!--          <a-input :disabled="showType=='detail'" v-model="queryParams.qrcode_name"></a-input>-->
          <a-auto-complete
              :disabled="showType==='detail'"
              v-model="queryParams.qrcode_name"
              :data-source="nameList"
              :filterOption="true"
              @select="onSelect"
              @search="onSearch"
              @change="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="编号" prop="qrcode_num" style="width: 45%">
          <a-input :disabled="showType=='detail'" v-model="queryParams.qrcode_num"></a-input>
        </a-form-model-item>
        <a-form-model-item label="位置" prop="qrcode_address" style="width: 45%">
          <a-input :disabled="showType=='detail'" v-model="queryParams.qrcode_address"></a-input>
        </a-form-model-item>
        <a-form-model-item label="轴位" prop="qrcode_axial" style="width: 45%">
          <a-input :disabled="showType=='detail'" v-model="queryParams.qrcode_axial"></a-input>
        </a-form-model-item>
        <a-form-model-item label="关联摄像机名称/编号" prop="monitor_num"  style="width: 45%">
          <a-input :disabled="showType=='detail'" v-model.trim="queryParams.monitor_num"></a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {getMonitorPointNameList, getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addQrcodeContrast,
  getEquipAndMachineInfoList,
  getEquipAndMachineNameList,
  modifyQrcodeContrast
} from "A/facilityandequipment";
import {mapState} from "vuex";
import {getMaintenanceScheduleConditionList} from "A/maintenance";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        qrcode_id:'',
        qrcode_type:'',
        qrcode_name:'',
        qrcode_num:'',
        qrcode_address:'',
        qrcode_axial:'',
        monitor_num:'',
        creator:'',
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        qrcode_type: [{required: true, message: '请输入类型'}],
        qrcode_name: [{required: true, message: '请输入名称'}],
        qrcode_num: [{required: true, message: '请输入编号'}],
        // qrcode_address: [{required: true, message: '请输入地址'}],
        monitor_num: [{required: true, message: '请输入关联摄像机名称/编号'}],
      },
      formData:{
        userdepsid:'',
      },
      nameList: [],
      monitorpointNameList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.getMonitorpointName();
    this.modalVisible = this.visible;
  },
  methods: {
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)];
    },
    onSelect(value) {
      //console.log('onSelect', value);
      getEquipAndMachineInfoList({qrcode_type:this.queryParams.qrcode_type,monitorpointnum:this.queryParams.monitorpointnum,name:this.queryParams.qrcode_name}).then(res=>{
        if(res && res.returncode == '0') {
          if(res.item.length>0){
            if(this.queryParams.qrcode_type==="机房"){
              this.queryParams.qrcode_num=res.item[0].machine_room_num
              this.queryParams.qrcode_axial=res.item[0].axial_position
            }else if(this.queryParams.qrcode_type==="巡查点"){
              this.queryParams.qrcode_num=res.item[0].point_qrcode
              this.queryParams.qrcode_address=res.item[0].address
              this.queryParams.qrcode_axial=res.item[0].axial_position
            }else if(this.queryParams.qrcode_type==="设备"){
              this.queryParams.qrcode_num=res.item[0].equipment_num
              this.queryParams.qrcode_address=res.item[0].address
              this.queryParams.qrcode_axial=res.item[0].axial_position
            }else if(this.queryParams.qrcode_type==="作业点"){
              this.queryParams.qrcode_num=res.item[0].point_qrcode
              this.queryParams.qrcode_address=res.item[0].address
              this.queryParams.qrcode_axial=res.item[0].axial_position
            }
          }
        }
      })
    },
    onChange(value) {
      //console.log('onChange', value);
    },
    getMonitorpointName(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointNameList=res.item
          this.queryParams.monitorpointnum=res.item[0].monitorpointnum
        }
      })
    },
    getQueryCondition(){
      this.queryParams.qrcode_name=""
      this.queryParams.qrcode_num=""
      this.queryParams.qrcode_axial=""
      this.queryParams.qrcode_address=""
      this.queryParams.monitor_num=""
      getEquipAndMachineNameList({qrcode_type:this.queryParams.qrcode_type,monitorpointnum:this.queryParams.monitorpointnum}).then(res=>{
        if(res && res.returncode == '0') {
          this.nameList=res.item
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            this.queryParams[key] = this.detailData[key] || '';
          }
        })
      }
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            this.queryParams.creator=this.userInfo.username
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addQrcodeContrast(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifyQrcodeContrast(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>